@import '../../../../../variables';

.pdlFilters {
  $pdlFilters-border-radius: 8px;
  /** change bootstrap class to make it block button group (full width) */
  .btn-group {
    display: flex;
  }
  .btn-outline {
    background-color: $pdl-white;
  }
  .btn {
    border-radius: $pdlFilters-border-radius !important;
    margin: 0px 2px;
    padding: 8px 0px;
  }

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .pdlButtonGroupContainer {
    flex: 6;
    border-radius: $pdlFilters-border-radius;
    background-color: $pdl-white;
  }
  .pdlMoreFilterIcon {
    cursor: pointer;
    flex: 1;
    fill: $pdl-black-80;
    height: 28px;
    width: 28px;
  }
}
