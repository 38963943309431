@import '../../../variables';

.pdlSkillSelection {
  height: 100%;
  width: 100%;

  .pdlSkillListContainer {
    height: 76%;
    width: 100%;
    padding: 0px 128px;
    overflow-y: scroll;

    .pdlSkillItem {
      display: flex;
      justify-content: space-between;
      padding: 16px 0px;
      border-bottom: 0.5px solid rgba(137, 131, 147, 0.5);
    }
  }
}
