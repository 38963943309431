@import '../../variables';

.pdlCarousel {

  .carousel-indicators { // overriding bootstrap carousel indecators
    bottom: 136px;

    li {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      border-top: none;
      border-bottom: none;
    }

    .active {
      width: 30px;
    }
  }

  .carousel-caption {
    right: 0;
    left: 0;
    bottom: 300px;
    text-align: center;
  }
}
