@import '../../../variables';

.pdlSearchBar {
  display: flex;
  height: 60px;
  background-color: $pdl-white;
  border-radius: 8px;
  padding: 0px 12px 0px 16px;
  align-items: center;
  justify-content: space-between;

  &--sm {
    height: 44px;
    padding: 0px 6px 0px 16px;
    border: 0.3px solid $pdl-light-line;
  }

  input {
    outline: none;
    border: none;
    width: 86%;
  }

  .pdlIcon {
    fill: $pdl-white;
  }


}

.pdlSearchResult {
  z-index: 16; /** some bootstrap components goes top without z-index: 16 */
}
