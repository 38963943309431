@import '../../../../variables';

.pdlTopBar {
  height: 86px;
  width: 100%;
  padding: 16px;
  border-bottom: 0.5px solid $pdl-black-10;
  display: flex;
  flex-direction: row;

  .pdlTopBarLeft {
    flex: 1;
    display: flex;
    align-items: center;

    .pdlBackButtonIcon {
      cursor: pointer;
      height: 44px;
      width: 44px;
    }
  }

  .pdlTopBarRight {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .tripleDotsIcon {
      cursor: pointer;
      height: 28px;
      width: 28px;
    }
  }
}
