@import '../../variables';

.pdl-EarningPageContainer {
  height: 100vh;
  width: 100%;
  background-color: $pdl-jumbotron-color;

  display: flex;
  align-items: center;
  justify-content: center;

  .pdl-EarningTableCard {
    background-color: $pdl-white;

    margin: 100px;
    height: 680px; // if change this, should see other 680px usages in only this scss file.
    width: 1200px;
    min-height: 680px;
    min-width: 900px;

    border-radius: 16px;

    display: flex;
    flex-direction: column;

    .pdl-Earning-TopBar {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .pdl-Earning-Table {
      flex: 1;
      width: 100%;
      height: 100%;

      table tbody {
        display: block;
        max-height: 550px; /// since the container height is fixed(680px)
        overflow-y: scroll;
      }

      table thead, table tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      .table thead th {
        border-bottom: none;
      }
    }
  }
}

/**
  Bootstrap class override
*/
.table-secondary th {
  border: none;
}
