@import '../../variables';

.pdlUserRating {

  .pdlStarsContainer {
    display: flex;

    .pdlStarIcon {
      margin: 2px;
      height: 18px;
      width: 18px;

      &--fill {
        fill: #FFC107;
      }

      &--outline-white {
        fill: $pdl-white;
      }
    }
  }
}
