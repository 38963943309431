$default-background-color: #ffffff;
$pdl-color-primary: #1877f2;
$pdl-color-danger: #F34F32;
$pdl-color-disabled: rgba(24, 119, 242, 0.5);
$pdl-color-warning: #ff8a00;
$default-background-color: #ffffff;

$pdl-black: rgba(17, 24, 41, 1); // #111829;
$pdl-black-p: rgba(19, 7, 39, 1); // #130727;
$pdl-black-80: rgba(66, 57, 82, 1); // #423952;
$pdl-black-50: rgba(137, 131, 147, 1); // #898393
$pdl-black-10: #E7E6E9;

$pdl-green-2: #4ada68;

$pdl-light-line: rgba(66, 57, 82, 0.2);
$pdl-input-bottom-border: rgba(207, 206, 206, 0.486);

$pdl-white: #ffffff;

$pdl-jumbotron-color: #f4f5f8;

$pdl-avatar-background: rgba(242, 235, 255, 1); // #423952
