@import '../../../../../../variables';

.pdl-Messenger-Message-QuoteMessageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: 16px 0px;
  align-items: center;

  &--highlighted {
    background-color: #E8F1FE;
  }
}
