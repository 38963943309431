@import '../../variables';

.pdlText {

  margin: 0;
  color: $pdl-black;

  &--bp {
    color: $pdl-black-p;
  }
  &--b80 {
    color: $pdl-black-80;
  }
  &--b50 {
    color: $pdl-black-50;
  }
  &--white {
    color: $pdl-white;
  }
  &--primary {
    color: $pdl-color-primary;
  }
  &--success {
    color: $pdl-green-2;
  }
  &--danger {
    color: $pdl-color-danger;
  }

  &--clickable {
    cursor: pointer;
  }
}
