@import '../../variables';

.pdlUserBadges {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 4px;

  .pdlProBadge {
    margin: 0px 6px;
  }
}
