@import '../../../../../variables';

.pdlProjectImages {
  width: 100%;
  margin: 0px;
  border-radius: 0px;
  background-color: $pdl-white;

  display: flex;
  flex-direction: column;

  .pdlSlider {
    width: 100%;
    margin: 18px 0px 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .pdlArrowIconStyle {
    fill: white;
    height: 56px;
    width: 56px;
  }
}
