@import '../../../../../../variables';

.pdl-Messenger-Message-OfferMessage {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: 16px 0px;
  align-items: center;
  background-color: #E8F1FE;
}
