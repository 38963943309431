@import '../../../../../variables';

.pdl-Messenger-ProjectDetail-Modal-Information {

  .pdlInlineInfos {
    width: calc(100% - 16px);
    margin: 8px;
    padding: 8px;
    border-radius: 16px;
    background-color: $pdl-white;
  }

  .pdlNonInlineInfos {
    width: calc(100% - 16px);
    margin: 8px;
    padding: 8px;
    border-radius: 16px;
    background-color: $pdl-white;
  }
}
