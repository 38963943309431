@import '../../variables';

$pdl-category-slider-height: 144px;

.pdlCategorySlider {

  height: $pdl-category-slider-height;

  .pdlScrollableArea {
    overflow-x: hidden;
    background-color: $pdl-white;
    padding-top: 24px !important; // re overwrite __spacing.scss padding

    box-shadow: 5px 10px 7px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
  }

  .pdlItem {
    cursor: pointer;
    height: 76px;
    padding: 0px;
    /// background-color: rgba(0, 0, 0, 0.03); // TODO: remove this
    border: none;
    background-position: center;
    background-size: cover;
    border-radius: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .pdlIcon {
      min-height: 40px;
      min-width: 40px;
      margin: 0px 0px 16px 0px;
    }
  }

  .pdlShifterButtonArea {
    height: $pdl-category-slider-height;
    width: 48px;
    display: flex;
    align-items: center;

    position: relative;
    top: -$pdl-category-slider-height+12px;
    left: -36px;

    &--right {
      top: -$pdl-category-slider-height - $pdl-category-slider-height + 12;
      left: calc(100% - 6px);
    }
  }
}
