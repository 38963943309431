@import "../../variables";

.pdlLoginForm {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  .login {
    .image {
      text-align: center;
    }
  }
  .form {
    padding-top: 80px;
  }
  .login-utill {
    padding: 10px;
    display: flex;
    flex-direction: row;
    .remember {
      display: flex;
      width: 100%;
      flex-direction: row;
      font-weight: 500;
    }
    .forget {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      color: red;
    }
  }
}
