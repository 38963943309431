@import '../../../variables';

.pdlProfilePic {
  width: 100%;
  display: flex;
  flex-direction: column;

  .pdlPhotoContainer {
    height: 182px;
    width: 182px;
    padding: 4px;
    border-radius: 100%;
    background-color: $pdl-color-primary;

    display: flex;
    align-items: center;
    justify-content: center;

    .pdlIcon {
      height: 68px;
      width: 68px;
      fill: $pdl-white;
    }
  }
}
