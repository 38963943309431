@import '../../../../variables';

.pdlBottomBar {
  height: 128px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 16px;

  .pdlIcon {
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin: 0px 12px 0px 12px;
  }

  .pdlIconSend {
    cursor: pointer;
    margin: 0px 8px 0px 24px;
  }

  .pdlMessageInput {
    flex: 1;
    height: 40%;
    border: 0.5px solid rgba(5, 131, 242, 0.5); /** TODO: use variable maybe? */
    border-radius: 8px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      outline: none;
      border: none;
      width: 94%;
    }
  }

  .pdlMessageSendIcon {
    height: 36px;
    width: 36px;

    fill: $pdl-color-primary;
    -ms-transform: rotate(90deg); /* IE 9 */
    transform: rotate(90deg);
  }
}
