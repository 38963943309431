@import '../../variables';

/** bootstrap overwrite */
.btn-warning {
  color: $pdl-white;
}
.btn-success {
  color: $pdl-white;
}
.btn-outline-primary {
  border: 1px solid $pdl-color-primary !important;
}
.btn-outline-warning {
  border: 1px solid $pdl-color-warning !important;
}
.btn-outline-success {
  border: 1px solid $pdl-green-2 !important;
}

.pdlButton {

  border-radius: 6px;
  border: none;

  &--wider {
    padding: 6px 34px;
    border-radius: 8px;
  }

  &--disabled, &--disabled:hover {
    background-color: $pdl-color-disabled !important;
    cursor: not-allowed !important;
    box-shadow: none !important;
  }
}
