@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.promotionalPage {
  overflow-x: hidden;
  * {
    box-sizing: border-box;
    font-family: Poppins;
  }
  body {
    margin: 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  p {
    font-family: Roboto;
  }
  .nav {
    position: relative;
    height: 80px;
    box-shadow: 0px 4px 4px rgba(11, 160, 107, 0.15);
    vertical-align: middle;
    background-color: #fff;
    z-index: 3;
  }
  .nav > h1 {
    margin: 0;
  }

  .oc {
    padding-top: 52px;
    padding-left: 44px;
    /* z-index: -1; */
    width: 100%;
  }
  .oc1 {
    padding-top: 70px;
    padding-left: 97px;
  }
  .oc2 {
    padding-top: 70px;
    padding-left: 70px;
  }

  .col {
    background: url(1.svg) no-repeat;
    width: 60%;
    height: 580px;
    z-index: 2;
  }
  .col-mobile {
    margin-top: 52px;
    background: url(map-mobile.svg) no-repeat;
  }

  .d1 {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    width: 60%;
  }
  .d1 > img {
    width: 100%;
  }

  .d2 {
    position: absolute;
    top: 23%;
    right: 25%;
    width: 35%;
  }
  .d2 > img {
    width: 100%;
    height: 100%;
  }
  .d2-mobile {
    top: 35%;
    right: -58%;
    position: absolute;
  }
  .d2-mobile > img {
    width: 53%;
  }
  .nav-left-padding {
    padding-left: 15.5vw;
  }
  .content {
    padding-left: 13vw;
    padding-top: 3vh;
  }
  .content > h1 {
    margin: 0;
    font-weight: 600;
    line-height: 75px;
    font-size: 50px;
  }
  .content > p {
    margin: 0;
    font-weight: 400;
    line-height: 36px;
    font-size: 24px;
  }
  .dot {
    position: relative;
    padding: 18px 17px;
    width: 500px;
    box-sizing: content-box;
  }
  .dot-mobile {
    position: relative;
    padding: 18px 15px;
    box-sizing: content-box;
  }

  #left-dot {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  #right-dot {
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
  .dot .follow-email {
    position: relative;
    width: 500px;
    margin-top: 80px;
    text-align: center;
    padding: 40px 0px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 2px #6ea1e49d solid;
    box-shadow: rgba(7, 99, 180, 0.384) 2px 2px 5px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }
  .dot-mobile .follow-email {
    position: relative;
    margin-top: 30px;
    text-align: center;
    padding: 20px 5px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 2px #6ea1e49d solid;
    box-shadow: rgba(7, 99, 180, 0.384) 2px 2px 5px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }
  .dot .follow-email > p {
    font-weight: 400;
    font-size: 18px;
    padding: 20px 0px;
    margin: 0;
  }
  .dot-mobile .follow-email > p {
    font-weight: 900;
    font-size: 1rem;
    padding: 20px 0px;
    margin: 0;
  }
  .dot .follow-email > a {
    text-decoration: none;
    background-color: #1877f2;
    height: 44px;
    width: 202px;
    padding: 9px 19px;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
    color: white;
  }
  .dot-mobile .follow-email > a {
    text-decoration: none;
    background-color: #1877f2;
    height: 44px;
    width: 202px;
    padding: 9px 19px;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
    color: white;
  }
  .follow-head {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: -25px;
    left: 25%;
    width: 250px;
    padding: 0px 0px;
    text-align: center;
    border: 2px solid #d1e4fc;
    box-shadow: 0px 20px 40px rgba(24, 119, 242, 0.15);
    border-radius: 31px;
    background-color: #ffffff;
  }
  .follow-head-mobile {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -25px;
    left: 10%;
    width: 80%;
    padding: 0px 0px;
    text-align: center;
    border: 2px solid #d1e4fc;
    box-shadow: 0px 20px 40px rgba(24, 119, 242, 0.15);
    border-radius: 31px;
    background-color: #ffffff;
  }
  .follow-head p {
    font-weight: 700;
    font-size: 35px;
    color: #1877f2;
    padding: 0px;
    margin: 0px;
  }
  .follow-head-mobile p {
    font-weight: 700;
    font-size: 25px;
    color: #1877f2;
    padding: 0px;
    margin: 0px;
  }
  .follow-email-mobile {
    background-color: #1877f2;
    height: 44px;
    width: 202px;
    padding: 9px 19px;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    z-index: 1;
  }
  .follow-email > a {
    text-decoration: none;
    color: white;
  }
  .container-padding {
    padding-top: 120px;
  }
  .container-padding h1 {
    font-weight: 600;
    line-height: 75px;
    font-size: 50px;
  }
  .round {
    background-color: #1877f2;
    height: 54px;
    width: 54px;
    border-radius: 50%;
    color: #fff;
    padding: 8px 8px;
  }
  .round > h1 {
    margin: 0;
  }
  .round-mobile {
    background-color: #1877f2;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: #fff;
    padding: 4px 0px;
  }
  .round-mobile-percentage {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: #fff;
    padding: 4px 0px;
  }
  .option-img {
    width: 100%;
  }
  .row-padding-top {
    padding-top: 40px;
  }
  .col-padding {
    padding-top: 50px;
    padding-left: 140px;
  }
  .col-padding h4 {
    margin: 0;
    line-height: 45px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 30px;
  }
  .col-padding .round {
    margin-top: 15px;
  }
  .diff-img {
    width: 100%;
  }
  .footer {
    position: relative;
    margin-top: 30%;
    height: 40vh;
    width: 100%;
    padding: 0px;
  }
  .footer-img {
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: -1;
  }
  .mail {
    position: absolute;
    width: 40%;
    top: -50%;
    height: 220px;
    background: #ffffff;
    border: 3px solid rgba(24, 119, 242, 0.5);
    border-radius: 16px;
    z-index: 1;
  }
  .mail p {
    /* margin: 0;
    padding: 0px 100px;
    padding-top: 40px; */
    color: #1877f2;
    font-family: Poppins;
    font-weight: 600;
    font-size: 20px;
    line-height: 48px;
    text-align: center;
  }
  .mail > .d-flex {
    width: 100%;
  }
  .mail form {
    /* padding: 0px 6vw;
    padding-top: 25px; */
    width: 100%;
  }
  .mail input[type="email"] {
    border: 0.5px solid #8cbbf9;
    border-radius: 8px;
    width: 90%;
    height: 40px;
    padding: 0px 10px;
    outline: none;
  }
  .mail .btn {
    width: 130px;
    height: 48px;
    margin-top: 5%;
    border-radius: 8px;
  }
  .device-text {
    margin-top: 15%;
  }
  .store-icon-1 {
    width: 38%;
    height: 20%;
  }
  .store-icon-2 {
    width: 30%;
  }
  .device-img {
    margin-top: -70%;
    z-index: 1;
    height: 600px;
    width: 600px;
  }

  .store-icon-polished {
    width: 212px;
    height: 81px;
  }
  .store-icon-polished-mobile {
    width: 80px;
    height: 30px;
  }

  .footer-bg {
    background-image: url("./8.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  .footer-polished {
    position: relative;
    top: -132px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .footer-polished-leftPane {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-polished-leftPane {
    flex-direction: column;
    align-items: center;
  }

  .mail-polished {
    height: 306px;
    width: 500px;
    padding: 80px 50px;
    background: #ffffff;
    border: 3px solid rgba(24, 119, 242, 0.5);
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mail-polished p {
    color: #1877f2;
    font-family: Poppins;
    font-weight: 600;
    font-size: 28px;
    line-height: 0px;
  }
  .mail-polished input[type="email"] {
    border: 0.5px solid #8cbbf9;
    border-radius: 8px;
    width: 90%;
    height: 55px;
    padding: 0px 10px;
    margin: 14px;
    outline: none;
  }
  .mail-polished .btn {
    width: 130px;
    height: 48px;
    border-radius: 8px;
  }
  /* mobile footer */
  .mobile-footer {
    background-image: url("./mobile-footer.svg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    padding-bottom: 10px;
  }
  .mobile-footer-device {
    width: 60px;
    height: 120px;
  }
  .mobile-worker {
    position: relative;
    margin-top: -110px;
    z-index: -1;
  }

  /* mobile mail box */
  .mail-polished-mobile {
    height: 200px;
    padding: 40px 25px;
    background: #ffffff;
    border: 1.5px solid rgba(24, 119, 242, 0.5);
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mail-polished-mobile p {
    color: #1877f2;
    font-family: Poppins;
    font-weight: 600;
    font-size: 20px;
    line-height: 0px;
  }
  .mail-polished-mobile input[type="email"] {
    border: 0.5px solid #8cbbf9;
    border-radius: 8px;
    width: 90%;
    height: 40px;
    padding: 0px 10px;
    margin: 9px;
    outline: none;
  }
}

.footer-relative {
  position: absolute;
  bottom: 0;
}
