@import "../../variables";

.pdlInlineInput {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  input {
    width: 100%;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    /* identical to box height */

    /* Color/Black/50 */
    color: $pdl-black-50;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  select {
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    /* Color/Black/Primary */

    color: $pdl-black-p;
  }
  span {
    margin: 0px 8px;
    width: 17px;
    border-left: 0.5px solid $pdl-black-50;
  }
  .line {
    width: 100%;
    height: 0px;
    margin: 8px 0px 0px;
    border-bottom: 0.5px solid $pdl-black-50;
  }
  .inline_input {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    .iconRight {
      width: 20px;
      height: 20px;
      cursor: pointer;
      justify-content: flex-end;
    }
  }
}
