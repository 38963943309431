@import '../../../variables';

.pdlOTP {

  .pdlHeaderNextGap {
    height: 96px;
    margin: 16px;
    border-top: 1px solid $pdl-color-primary;
  }

  input {
    height: 80px;
    width: 70px; /** this didn't work so added inputStyle prop to component */
    background-color: $pdl-white;
    border: 0.5px solid $pdl-color-primary;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: $pdl-color-primary;
  }

  .pdlSeparator {
    width: 4px;
  }

  .pdlResendOTPButtonContainer {
    margin: 16px 0px 32px 0px;
    display: flex;
    flex-direction: row-reverse;
  }

  .pdlButtonPreviousGap {
    height: 70px;
  }
}
