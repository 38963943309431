@import '../../../../../../variables';

.pdlMessage {
  .pdlUpper {
    display: flex;
    width: 100%;
    margin: 8px 0px;

    &--left {
      flex-direction: row;
      align-items: flex-end;
    }

    &--right {
      flex-direction: row-reverse;
      align-items: flex-end;
    }

    .pdlAvatarContainer {
      height: 30px;
      width: 42px;
      margin: 0px 4px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pdlMessageTextContainer {
      background-color: #C4C4C4;
      max-width: 400px;
      border-radius: 8px;
      padding: 8px 18px;

      &--right {
        background-color: $pdl-color-primary;
      }
    }
  }

  .pdlLower {
    display: flex;
    width: 100%;
    padding: 0px 48px;

    &--left {
      flex-direction: row;
    }

    &--right {
      flex-direction: row-reverse;
    }
  }
}


.pdlSpecialShape {
  $shape-size: 16px;
  min-height: $shape-size;
  min-width: 32px; // double of $shape-size
  display: inline-flex;
  background-color: #C4C4C4; /** TODO: use variable maybe? */

  &--left {
    margin-right: -16px; /// negative of $shape-size
  }

  &--right {
    margin-left: -16px; /// negative of $shape-size
    background-color: $pdl-color-primary;
  }

  .pdlCircle {
    height: $shape-size;
    width: $shape-size;
    border-radius: 0px 0px $shape-size 0px;
    background-color: rgba(255, 255, 255, 1);

    &--right {
      border-radius: 0px 0px 0px $shape-size;
    }
  }
}
