@import '../../../../../variables';

.pdl-msg-SearchBar {
  height: 56px;
  width: 100%;
  border-radius: 56px;
  background-color: $pdl-white;

  display: flex;
  align-items: center;

  .pdlSearchIcon {
    fill: $pdl-black;
    margin: 0px 8px 0px 16px;
  }

  input {
    width: 70%;
    outline: none;
    border: none;
  }
}
