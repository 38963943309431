@import '../../variables';

$pdl-left-pane-background-color: #f8f9fa;
$pdl-right-pane-background-color: #ffffff;

.pdlDesignLibrary {
  .pdlLeftPane {
    height: 100vh;
    width: 100%;
    background-color: $pdl-left-pane-background-color;

    .pdlHeaderWrapper {
      padding: 8px 0px 8px 16px;
      cursor: pointer;
    }

    .pdlMenuNameWrapper {
      margin: 8px 0px 8px 32px;
      cursor: pointer;
    }
  }

  .pdlRightPane {
    background-color: $pdl-right-pane-background-color;

    .pdlHeaderWrapper {
      margin: 32px 0px 32px 0px;
      cursor: pointer;
    }
  }
}
