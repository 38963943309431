@import '../../../variables';

.pdlProjectDetailDrawer {
}

.pdlProjectDetailDrawer-edit-icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

/**
  * Since modal is not gonna be child element of pdlProfileDrawer we need to write the class globally.
  */
.pdl-project-detail-drawer-modal-dialog {
  height: 100vh;
  max-width: 394px;

  margin: 0px;

  &--right { /** TODO: use this for service providers */
    margin-left: calc(100% - 394px);
  }
}
.pdl-project-detail-drawer-modal-content {
  height: 100vh;
  max-width: 394px;
  overflow: hidden;
}
.pdl-project-detail-drawer-modal-header {
  text-align: center;
}
.pdl-project-detail-drawer-modal-body {
  background-color: $pdl-jumbotron-color;
  padding: 0px;
}
