@import '../../variables';

.pdlPageFoundation {

  margin: 0;

  .pdlPageAreaContainer {

    height: 100vh;
    width: 100%;
    overflow: hidden;
    padding: 0px;


    .pdlPageArea {
      padding: 0px;
      height: 100vh;
      width: 100%;
      overflow: hidden;

      &--scrollable {
        overflow-y: scroll;
      }

      &--scrollbarHide {
        width: calc(100% + 18px);
      }
    }
  }

}
