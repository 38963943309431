@import '../../../variables';

.pdlSuccessMessage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pdlTikContainer {
    background-color: $pdl-green-2;
    height: 116px;
    width: 116px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
