@import './_variables';

$theme-colors: (
  "primary": $pdl-color-primary,
  "warning": $pdl-color-warning,
  "success": $pdl-green-2,
);

@import "~bootstrap/scss/bootstrap";
@import url(https://fonts.googleapis.com/css?family=Manrope|Poppins:400italic,700italic,400,700);



.pdlApp {
  h1, h2, h3, h4, h5, h6, p, input {
    font-family: Poppins;
  }
}

body {
  background-color: $default-background-color;
}

/**
  * bootstrap overrides
  */
.popover {
  border: 1px solid $pdl-color-primary;
  box-shadow: 0px 2px 8px rgba(24, 119, 242, 0.1);
  z-index: 1040; /** to make it shown under modals */

  .arrow::before {
    border-bottom-color: $pdl-color-primary;
  }
}
