@import '../../../../../variables';

.pdlCard {

  /** bootstrap card-text change */
  .card {
    box-shadow: 2px 0px 8px rgba(24, 119, 242, 0.15);
    border-radius: 8px;
  }
  .card-text {
    text-align: center;
  }
  .card-img, .card-img-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 128px;
  }
}
