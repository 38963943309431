@import '../../variables';

.pdlCheckBox {
  cursor: pointer;
  height: 18px;
  width: 18px;
  border: 1px solid $pdl-color-primary;
  display: flex;
  align-items: center;
  justify-content: center;

  &--checked {
    background-color: $pdl-color-primary;
  }

  .pdlIcon {
    min-height: 18px;
    min-width: 18px;
    font-weight: bold;
    fill: $pdl-white;
  }
}
