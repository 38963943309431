@import '../../variables';

.pdlIconButton {
  cursor: pointer;
  box-shadow: 0px 2px 8px rgba(66, 57, 82, 0.15);
  height: 48px;
  width: 48px;
  border-radius: 48px;
  background: $default-background-color;

  &--primary {
    background: $pdl-color-primary;
  }

  .pdlIcon {
    height: 24px;
    width: 24px;
    margin: 12px;

    fill: $pdl-color-primary; // TODO: use variables
    &--primary {
      fill: $default-background-color;
    }
  }
}
