@import '../../../variables';

.pdlLeftPane {
  height: calc(100vh - 32px);
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  .pdlCardContainer {
    margin-bottom: 16px;
  }

  .pdlSearchBarContainer {
    margin-bottom: 16px;
  }

  .pdlFiltersContainer {
    margin-bottom: 16px;
  }

  .pdlChannelListContainer {
    flex-grow: 1;
    overflow-y: scroll;
  }
}
