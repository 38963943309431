@import "../../../variables";

.pdlRegistrationForm {
  display: inherit;
  .sigin-up {
    margin-top: 20px;
    .image {
      padding: 130px 0px;
      text-align: center;
    }
    h2 {
      font-weight: bold;
    }
  }
}
