@import '../../variables';

$pdl-main-pane-height: 340px;

.pdlHappyFaceSlider {

  height: $pdl-main-pane-height;

  .pdlMain {
    height: $pdl-main-pane-height;
    box-shadow: 5px 10px 7px rgba(0, 0, 0, 0.03);
    border: 0.5px solid #1877F2;
    border-radius: 8px;

    .pdlContents {
      text-align: center;
      padding: 76px 90px;

      .pldQuoteEndIcon {
        margin-top: 74px;
      }

      .pdlParagraphContainer {
        text-align: center;
        margin-top: 8px;
      }
    }
  }

  .pdlShifterButtonArea {
    height: $pdl-main-pane-height;
    width: 48px;
    display: flex;
    align-items: center;

    position: relative;
    top: -$pdl-main-pane-height;
    left: -24px;

    &--right {
      top: -$pdl-main-pane-height - $pdl-main-pane-height;
      left: calc(100% - 24px);
    }
  }
}
