@import "../../variables";

.pdlRegistration {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &--jumbotron-bg {
    background-color: $pdl-jumbotron-color;
  }

  .pdlSingleInputContainer {
    height: 766px;
    width: 752px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: $pdl-white;
    border-radius: 16px;
    box-shadow: 0px 5px 20px rgba(24, 119, 242, 0.1);
    padding: 0px 200px;

    &--skill-selector {
      padding: 0px 0px;
    }
  }
}
