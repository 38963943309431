@import '../../../../../variables';

.pdlUserReviews {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  background-color: $pdl-white;

  display: flex;
  flex-direction: column;

  .pdlAvatarContainer {
    width: 100%;
    margin: 18px 0px 12px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pdlRatingContainer {
    width: 100%;
    margin: 12px 0px 32px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pdlReviewListContainer {
    flex: 1;
    padding: 0px 12px;
    // overflow-y: scroll;

    .pdlReviewItem {
      display: flex;
      margin-top: 12px;

      .pdlReviewerAvatar {
        margin-right: 12px;
      }
    }
  }
}
