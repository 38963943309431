@import '../../../variables';

.pdlProfileDrawer {
}

/**
  * Since modal is not gonna be child element of pdlProfileDrawer we need to write the class globally.
  */
.pdl-profile-drawer-modal-dialog {
  height: 100vh;
  max-width: 360px;

  margin: 0px;
  margin-left: calc(100% - 360px);
}
.pdl-profile-drawer-modal-content {
  height: 100vh;
  max-width: 360px;
  overflow-y: scroll;
}
.pdl-profile-drawer-modal-header {
  text-align: center;
}