@import '../../../../../variables';

.pdlChannelCardUser {
  width: 100%;
  background-color: $pdl-white;
  border-radius: 16px;
  margin-bottom: 16px;
  display: flex;

  &--addedToChat {
    background-color: $pdl-color-primary;
  }

  .pdlAvatarContainer {
    margin: 16px 0px 0px 16px;
  }

  .pdlNameAndRaring {
    margin: 0px 8px;
  }

  .pdlPriceAndOptions {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
