@import '../../../variables';

.pdlLocationPicker {
  height: 60px;
  width: 218px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $pdl-white;
  padding: 0px 28px 0px 28px;
  border-radius: 8px;

  &--sm {
    height: 40px;
    width: 180px;
    padding: 0px 14px 0px 14px;
    justify-content: space-around;
  }

  .pdlIcon {
    fill: $pdl-color-primary;
  }
}
